/* eslint-disable prettier/prettier */
export const iconName = 'face_frowning'
export const width = 30
export const height = 30
export const svgPathData = 'M15,30A15,15,0,1,1,30,15h0A15,15,0,0,1,15,30ZM15,2A13,13,0,1,0,28,15h0A13,13,0,0,0,15,2Zm-5,8a2,2,0,1,1-2,2A2,2,0,0,1,10,10Zm10,0a2,2,0,1,1-2,2A2,2,0,0,1,20,10Zm1.19,13.58a1,1,0,0,1-1-.8,5.32,5.32,0,0,0-10.43,0,1,1,0,0,1-2-.4,7.32,7.32,0,0,1,14.35,0,1,1,0,0,1-.78,1.18h0A.65.65,0,0,1,21.19,23.58Z'

export const define = {
  iconName,
  icon: {
    width,
    height,
    svgPathData,
  },
}

export const faceFrowning = define
