/* eslint-disable prettier/prettier */
export const iconName = 'face_smiling'
export const width = 30
export const height = 30
export const svgPathData = 'M15,30A15,15,0,1,1,30,15h0A15,15,0,0,1,15,30ZM15,2A13,13,0,1,0,28,15h0A13,13,0,0,0,15,2Zm-5,8a2,2,0,1,1-2,2A2,2,0,0,1,10,10Zm10,0a2,2,0,1,1-2,2A2,2,0,0,1,20,10ZM15,24a7.12,7.12,0,0,1-7-5.82A1,1,0,0,1,8.84,17a1,1,0,0,1,1.15.79,5.1,5.1,0,0,0,10,0,1,1,0,0,1,2,.36A7.1,7.1,0,0,1,15,24Z'

export const define = {
  iconName,
  icon: {
    width,
    height,
    svgPathData,
  },
}

export const faceSmiling = define
