<template>
  <CBarChart :datasets="datasets" :options="computedOptions" />
</template>

<script>
import CBarChart from '@/components/common/CBarChart.vue'

Chart.Tooltip.positioners.custom = (elements, eventPosition) => {
  const ps = elements[0].tooltipPosition();
  const area = elements[0]._chart.chartArea;
  return { 
    x: ps.x,
    // y: (area.top + area.bottom) / 2 
    y: area.bottom
    };
}

export default {
  components: {
    CBarChart,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
    yAxisSuggestedMax: {
      type: Number,
      required: true,
    },
    xAxisMinTick: {
      type: String,
      required: true,
    },
    xAxisMaxTick: {
      type: String,
      required: true,
    },
    xAxisTickUnit: {
      type: String,
      default: 'hour',
    },
    yAxisMaxValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    datasets() {
      return [
        {
          data: this.data,
          backgroundColor: 'rgba(0, 47, 71, 0.3)',
        },
      ]
    },
    computedOptions() {
      return Object.assign(
        {},
        {
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  display: true,
                  beginAtZero: true,
                  maxTicksLimit: 4,
                  // suggestedMax: this.yAxisSuggestedMax,
                  // stepSize: this.yAxisSuggestedMax / 5,
                  max: this.yAxisMaxValue,
                },
                gridLines: {
                  drawBorder: false,
                  borderDashOffset: 30,
                  drawTicks: false,
                  color: '#DDD',
                  zeroLineColor: '#DDD',
                },
              },
            ],
            xAxes: [
              {
                type: 'time',
                offset: true,
                gridLines: {
                  display: false,
                },
                bounds: 'ticks',
                time: {
                  unit: this.xAxisTickUnit,
                  displayFormats: {
                    hour: 'H:mm',
                    day: 'M/D',
                  },
                  tooltipFormat: 'M/D H:mm',
                },
                ticks: {
                  fontSize: 10,
                  fontColor: '#222',
                  fontFamily: '"Arial", sans-serif',
                  maxRotation: 0,
                  min: this.xAxisMinTick,
                  max: this.xAxisMaxTick,
                  lineHeight: 1,
                  autoSkip: true,
                  autoSkipPadding: 20,
                  padding: -2,
                },
              },
            ],
          },
          tooltips: {
            intersect: false,
            mode: "index",
            position: "custom",
            // yAlign: "center"
          },
        },
        this.options
      )
    },
  },
}
</script>
