<script>
import { mergeData } from 'vue-functional-data-merge'
import { faceFrowning } from '@/assets/modules/icon/js/regular/face_frowning'
import { faceNeutral } from '@/assets/modules/icon/js/regular/face_neutral'
import { faceSmiling } from '@/assets/modules/icon/js/regular/face_smiling'

const Icon = {
  faceFrowning: faceFrowning.icon,
  faceNeutral: faceNeutral.icon,
  faceSmiling: faceSmiling.icon,
}
const IconKeys = Object.keys(Icon)

const Color = {
  clean: '#1D8DD5',
  alert: '#DBB618',
  attention: '#E54B26',
}

export default {
  name: 'PShopBadge',
  functional: true,
  props: {
    iconName: {
      type: String,
      default: 'faceNeutral',
      validator: (val) => IconKeys.includes(val),
    },
    fillColor: {
      type: String,
      default: '#000',
    },
  },
  render(h, { props, data }) {
    const computedClass = ['p-shopGraphIcon']

    const icon = Icon[props.iconName]
    const fillColor = Color[props.fillColor] || props.fillColor

    const componentData = mergeData(data, {
      class: computedClass,
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: `0 0 ${icon.width} ${icon.height}`,
        fill: fillColor,
      },
    })

    return h('svg', componentData, [
      h('path', { attrs: { d: icon.svgPathData } }),
    ])
  },
}
</script>
