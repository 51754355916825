<script>
import Chart from 'chart.js'
export default {
  name: 'CChart',
  type: '',
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: [Array, String],
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: undefined,
    }
  },
  computed: {
    canvas() {
      return this.$refs.canvas
    },
    safeId() {
      // as long as this._uid() works there is no need to generate the key
      const key = () => Math.random().toString(36).replace('0.', '')
      return '__safe_id__' + (this._uid || key())
    },
    computedDatasets() {
      return this.datasets
    },
    computedLabels() {
      if (this.labels && typeof this.labels !== 'string') {
        return this.labels
      } else if (
        !this.datasets ||
        !this.datasets[0] ||
        !this.datasets[0].data
      ) {
        return []
      }
      const emptyLabels = Array(this.datasets[0].data.length).fill('')
      if (this.labels === 'indexes') {
        return emptyLabels.map((u, i) => i + 1)
      } else if (this.labels === 'months') {
        return emptyLabels.map((u, i) => this.$options.months[i % 12])
      }
      return emptyLabels
    },
    computedData() {
      return {
        datasets: this.computedDatasets,
      }
    },
    chartConfig() {
      return {
        type: this.$options.type,
        data: this.computedData,
        options: this.options,
        plugins: this.plugins,
      }
    },
  },
  mounted() {
    this.renderChart()
  },
  beforeDestroy() {
    this.destroyChart()
  },
  watch: {
    chartConfig() {
      this.updateChart()
    },
  },
  methods: {
    renderChart() {
      this.destroyChart()
      this.chart = new Chart(this.canvas.getContext('2d'), this.chartConfig)
    },
    updateChart() {
      Object.assign(this.chart, this.chartConfig)
      this.chart.update()
    },
    destroyChart() {
      if (this.chart) {
        this.chart.destroy()
      }
    },
  },
  render(h) {
    return h('div', [
      h('canvas', {
        attrs: {
          id: this.safeId,
        },
        ref: 'canvas',
      }),
    ])
  },
}
</script>
