import { requestPublicApi } from '@/assets/modules/request'

/**
 * アクセスログ情報を登録する
 * @param {string} facilityIdSectionId
 * @param {string} accessType
 * @param {Array} url
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse>}
 */
export function postAccessLog(facilityIdSectionId, accessType, url) {
  return requestPublicApi.post('/accesslog', {
    facilityIdSectionId,
    accessType,
    url,
  })
}
